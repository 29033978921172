import axios from 'axios'

class UploadFilesService {
  uploadFiles (data, onUploadProgress) {
    const formData = new FormData()
    formData.append('file', data.file)

    return axios.post('/files/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  }

  getFiles () {
    return axios.get(
      '/files',
    )
  }

  deleteFile (fileId) {
    return axios.delete(
      '/files/' + fileId,
    )
  }
}

export default new UploadFilesService()
