<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Upload Files"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <template v-if="uploading">
      <v-progress-linear
        v-model="progress"
        color="light-blue"
        height="25"
        reactive
        striped
      >
        <strong>{{ progress }} %</strong>
      </v-progress-linear>
    </template>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Upload Files
          </v-card-title>
          <template>
            <v-file-input
              show-size
              label="Click to select files to upload."
              multiple
              @change="selectFile"
            />
          </template>
          <v-row dense>
            <v-col class="text-right">
              <v-btn
                color="primary"
                :disabled="file === undefined || file == '' || uploading"
                @click="handleFileUpload()"
              >
                Upload
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ items.length }} Files
          </h2>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['name']"
          >
            <template #item.url="{value}">
              <a
                :href="value"
                target="_blank"
              >
                {{ value }}
              </a>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    @click="copyToClipboard(value)"
                  >
                    mdi-clipboard
                  </v-icon>
                </template>
                <span>Copy to Clipboard</span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import UploadFilesService from '@/services/UploadFilesService'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'

  export default {
    name: 'UploadFiles',
    components: {
    },
    filters: {
    },
    mixins: [formatterMixin, navigationMixin],
    data () {
      return {
        message: 'Copy These Text',
        dialog: false,
        items: [],
        files: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        singleExpand: false,
        headers: [
          { text: 'Filename', value: 'filename' },
          { text: 'URL', value: 'url', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        file: '',
        progress: 0,
        uploading: false,
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      ...mapGetters(['accountId']),
    },
    watch: {
      accountId () {
        this.loadFiles()
      },
    },
    created () {
      this.loadFiles()
    },
    methods: {
      onCopy: function (e) {
        alert('You just copied: ' + e.text)
      },
      loadFiles: function () {
        this.items = []
        this.loading = true
        UploadFilesService.getFiles()
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      copyToClipboard (item) {
        this.$copyText(item)
      },
      deleteItem (item) {
        const index = this.items.indexOf(item)
        if (confirm('Are you sure you want to delete this file?') && this.items.splice(index, 1)) {
          // Call the API to update.
          this.loading = true
          UploadFilesService.deleteFile(item.id)
            .then(response => {
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              if (!error.response) {
                this.error = 'Network Error. Check your internet connection.'
              } else {
                var errorMessage = error.response.data.error
                if (errorMessage) {
                  this.error = errorMessage
                } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
              }
            })
        }
      },
      handleFileUpload () {
        this.uploading = true
        this.progress = 1
        var numberFilesToUpload = this.file.length
        var numberFilesUploaded = 0
        for (const file of this.file) {
          var data = {
            file: file,
          }

          UploadFilesService.uploadFiles(data, (event) => {
          })
            .then((response) => {
              numberFilesUploaded += 1
              var progress = Math.round((100 * numberFilesUploaded) / numberFilesToUpload)
              this.progress = progress
              if (numberFilesUploaded === numberFilesToUpload) {
                this.progress = 0
                this.file = undefined
                this.uploading = false
                this.loadFiles()
              }
            })
            .catch(() => {
              this.progress = 0
              this.file = undefined
              this.uploading = false
            })
        }
      },

      selectFile (file) {
        this.progress = 0
        this.file = file
      },
    },
  }
</script>
